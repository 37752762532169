import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Chip,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import "simplebar-react/dist/simplebar.min.css";
import { SampleDetails, SampleDetailsSkeleton } from "./subComponents/SampleDetails";
import { useGetSampleById, useUpdateSampleMutation } from "../../queries/QualityControlQueries";
import { SampleAdmin, SampleAdminSkeleton } from "./subComponents/SampleAdmin";
import { SampleCharacteristics, SampleCharacteristicsSkeleton } from "./subComponents/SampleCharacteristics";
import { Sample } from "../../types/QualityControlModels";
import { SampleSieveSetAnalysis, SampleSieveAnalysisSkeleton } from "./subComponents/SampleSieveSetAnalysis";
import { SampleCrushAnalysis } from "./subComponents/SampleCrushAnalysis";
import { SamplePlantSettings } from "./subComponents/SamplePlantSettings";
import { SampleStatusChip } from "./subComponents/SampleStatusChip";
import { cloneDeep, isEqual } from "lodash";
import { LocationOnOutlined, Print, Repeat, ScienceOutlined } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { useUserHasPermission } from "../../hooks/auth/useUserHasPermission";
import { PERMISSIONS } from "../../hooks/auth/useUserRoles";

export const QualityControlSample = () => {
    const navigate = useNavigate();
    const { siteCode, sampleId, mode } = useParams();
    const userCanManage = useUserHasPermission(PERMISSIONS.MANAGE);
    const userCanEdit = useUserHasPermission(PERMISSIONS.EDIT);
    const [isTabView, setIsTabView] = useState(true);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [sampleUpdate, setSampleUpdate] = useState<Sample>(null);
    const [sample, setSample] = useState<Sample>(null);
    const [formErrors, setFormErrors] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({
        contentRef,
        pageStyle: `
        @media print {
          body {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
          }
          @page {
            margin: 0;
          }
          .print-content {
            transform: scale(1);
            transform-origin: top center;
            width: 100%; /* Ensure it fits the page */
            height: auto;
            margin: 0 auto;
          }
        }
      `,
    });
    const isCoreSample = useMemo(
        () => sampleUpdate?.location?.isCoreSampleLocation ?? sample?.location?.isCoreSampleLocation,
        [sample?.location?.isCoreSampleLocation, sampleUpdate?.location?.isCoreSampleLocation]
    );

    function getDifferentProperties(obj1, obj2) {
        const differentProperties = [];

        // Iterate over each property in obj1
        for (const key in obj1) {
            // Check if the property exists in obj2 and if their values are different
            if (Object.prototype.hasOwnProperty.call(obj2, key) && !isEqual(obj1[key], obj2[key])) {
                differentProperties.push(key);
            }
        }

        // Iterate over each property in obj2
        for (const key in obj2) {
            // Check if the property exists in obj1 and if their values are different
            if (Object.prototype.hasOwnProperty.call(obj1, key) && !isEqual(obj1[key], obj2[key])) {
                differentProperties.push(key);
            }
        }

        return differentProperties;
    }

    const isFormChanged = useMemo(() => {
        const sampleClone = cloneDeep(sample);
        const sampleUpdateClone = cloneDeep(sampleUpdate);

        // Remove ids from ranges in sampleClone
        if (sampleClone?.sieveSetAnalysis?.ranges?.length) {
            sampleClone.sieveSetAnalysis.ranges.forEach((range) => {
                delete range.id;
            });
        }

        // Remove ids from ranges in sampleUpdateClone
        if (sampleUpdateClone?.sieveSetAnalysis?.ranges?.length) {
            sampleUpdateClone.sieveSetAnalysis.ranges.forEach((range) => {
                delete range.id;
            });
        }
        return !isEqual(sampleUpdateClone, sampleClone);
    }, [sample, sampleUpdate]);

    const { data: sampleFromServer, isLoading: isLoadingSampleFromServer }: { data: Sample; isLoading: boolean } = useGetSampleById({
        sampleId,
        options: {
            enabled: !!sampleId,
            onSuccess: (response: Sample) => {
                setSample({
                    ...response,
                    location: response.location,
                });
            },
        },
    });

    const { mutate: updateSample, isLoading: isUpdatingSample } = useUpdateSampleMutation();

    const handleSave = (onSuccess = null) => {
        if (sampleUpdate?.sieveSetAnalysis?.sieveAnalyses?.some((sieve) => Number(sieve.finalWeight) < 0)) {
            alert("There are negative final weights. Sample will save but will not be marked complete until corrected.");
        }
        updateSample(sampleUpdate, {
            onSuccess: () => {
                if (onSuccess) {
                    onSuccess();
                }
            },
        });
    };

    const handleDiscardChangesAndExit = () => {
        discardChanges();
        navigate(-1);
    };

    const handleExit = () => {
        navigate(-1);
    };

    const discardChanges = () => {};

    useEffect(() => {
        if (sampleFromServer) {
            setSample(sampleFromServer);
            setSampleUpdate(sampleFromServer);
        }
    }, [sampleFromServer]);

    return (
        <>
            <DiscardChangesWarningModal showWarningModal={showWarningModal} handleModalClose={() => setShowWarningModal(false)} handleDiscardChangesAndExit={handleDiscardChangesAndExit} />
            {sample ? (
                <Card
                    sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                        overflow: "unset",
                    })}
                    ref={contentRef}
                    className="print-content"
                >
                    <CardHeader
                        sx={(theme) => ({ backgroundColor: theme.palette.grey[300], position: "sticky", top: 0, opacity: 1, zIndex: 999 })}
                        title={
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {sample.id && sample.sampleGroupId && (
                                        <Stack direction="row" justifyContent="flex-start" spacing={2}>
                                            <Chip label={<Typography>{sample.id}</Typography>} icon={<ScienceOutlined fontSize="medium" />} />
                                            <SampleStatusChip status={sample?.status} />
                                            {!!sample?.isRepeat && <Chip label={<Typography>Sample {sample?.repeatSampleId} Repeat</Typography>} icon={<Repeat fontSize="small" />} />}
                                            {!userCanEdit && <Chip label="Read-only" />}
                                            {sample?.location?.plant?.name && sample?.location?.name && (
                                                <Chip icon={<LocationOnOutlined />} label={`${sample?.location?.plant?.name} - ${sample?.location?.name}`} />
                                            )}
                                        </Stack>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                        <IconButton onClick={() => reactToPrintFn()}>
                                            <Print />
                                        </IconButton>
                                        <SaveAndCloseButton
                                            handleSave={handleSave}
                                            handleExit={handleExit}
                                            isChanged={isFormChanged}
                                            isReadOnly={!userCanEdit}
                                            isFormError={formErrors}
                                            isLoading={isSaving || isUpdatingSample}
                                            disabled={isProcessing}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                {isTabView && (
                                    <Tabs component="div" value={currentTabIndex} onChange={(event, index) => setCurrentTabIndex(index)} textColor="primary" indicatorColor="secondary">
                                        <Tab label="Details" disabled={isSaving} />
                                        <Tab label="Sieve Analysis" disabled={isSaving} />
                                        <Tab label="Crush Analysis" disabled={isSaving} />
                                        <Tab label="Characteristics" disabled={isSaving} />
                                        <Tab label="Plant Settings" disabled={isSaving || !sample?.location || !sampleUpdate?.location} />
                                        {userCanManage && <Tab label="Admin" disabled={isSaving} />}
                                    </Tabs>
                                )}
                                {/* <Grid item xs={2} display="flex" flexDirection="row-reverse">
                                <FormControlLabel
                                    control={
                                        <Switch checked={isTabView} onChange={handleTabViewChange} disabled={isSaving} />
                                    }
                                    label={<Typography variant="button">Tab View</Typography>}
                                />
                             */}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1}>
                            <Grid item role="tabPanel" hidden={isTabView && currentTabIndex !== 0} aria-labelledby="sample-details-tab" xs={12}>
                                {!sample ? (
                                    <SampleDetailsSkeleton />
                                ) : (
                                    (currentTabIndex === 0 || !isTabView) && (
                                        <SampleDetails showTitle={!isTabView} sampleUpdate={sampleUpdate} setSampleUpdate={setSampleUpdate} isReadOnly={!userCanEdit} disabled={isSaving} />
                                    )
                                )}
                            </Grid>
                            <Grid item role="tabPanel" hidden={isTabView && currentTabIndex !== 1} aria-labelledby="sample-sieve-analysis-tab" xs={12}>
                                {isLoadingSampleFromServer ? (
                                    <Skeleton />
                                ) : (
                                    (currentTabIndex === 1 || !isTabView) && (
                                        <SampleSieveSetAnalysis
                                            showTitle={!isTabView}
                                            sample={sample}
                                            sampleUpdate={sampleUpdate}
                                            setSampleUpdate={setSampleUpdate}
                                            setIsProcessing={setIsProcessing}
                                            isCoreSample={isCoreSample}
                                            isReadOnly={!userCanEdit}
                                            disabled={isSaving}
                                        />
                                    )
                                )}
                            </Grid>
                            <Grid item role="tabPanel" hidden={isTabView && currentTabIndex !== 2} aria-labelledby="sample-crush-analysis-tab" xs={12}>
                                {isLoadingSampleFromServer ? (
                                    <SampleSieveAnalysisSkeleton />
                                ) : (
                                    (currentTabIndex === 2 || !isTabView) && (
                                        <SampleCrushAnalysis showTitle={!isTabView} sampleUpdate={sampleUpdate} setSampleUpdate={setSampleUpdate} isReadOnly={!userCanEdit} disabled={isSaving} />
                                    )
                                )}
                            </Grid>
                            <Grid item role="tabPanel" hidden={isTabView && currentTabIndex !== 3} aria-labelledby="sample-characteristics-tab" xs={12}>
                                {isLoadingSampleFromServer ? (
                                    <SampleCharacteristicsSkeleton />
                                ) : (
                                    (currentTabIndex === 3 || !isTabView) && (
                                        <SampleCharacteristics showTitle={!isTabView} sample={sample} setSample={setSampleUpdate} isReadOnly={!userCanEdit} disabled={isSaving} />
                                    )
                                )}
                            </Grid>
                            <Grid item role="tabPanel" hidden={isTabView && currentTabIndex !== 4} aria-labelledby="sample-plant-settings-tab" xs={12}>
                                {isLoadingSampleFromServer ? (
                                    <Skeleton />
                                ) : (
                                    (currentTabIndex === 4 || !isTabView) && (
                                        <SamplePlantSettings showTitle={!isTabView} sample={sample} sampleUpdate={sampleUpdate} setSampleUpdate={setSampleUpdate} isReadOnly={!userCanEdit} disabled={isSaving} />
                                    )
                                )}
                            </Grid>
                            {userCanManage && (
                                <Grid item role="tabPanel" hidden={isTabView && currentTabIndex !== 5} aria-labelledby="sample-admin-tab" xs={12}>
                                    {isLoadingSampleFromServer ? (
                                        <SampleAdminSkeleton />
                                    ) : (
                                        (currentTabIndex === 5 || !isTabView) && (
                                            <SampleAdmin showTitle={!isTabView} sample={sample} unsavedChanges={isFormChanged} isReadOnly={!userCanEdit} disabled={isSaving} />
                                        )
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                <Card
                    sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                    })}
                >
                    <CardHeader
                        sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
                        title={
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Stack direction="row" justifyContent="flex-start" spacing={2}>
                                        <Skeleton animation="pulse">
                                            <Chip />
                                        </Skeleton>
                                        <Skeleton animation="pulse">
                                            <Chip />
                                        </Skeleton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                        <Skeleton variant="rectangular" animation="wave" width="141.5px" height="36.5px" />
                                    </Stack>
                                </Grid>
                            </Grid>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Stack direction="row" spacing={0.5}>
                                    <Skeleton animation="wave" width="72px" height="49px" />
                                    <Skeleton animation="wave" width="72px" height="49px" />
                                    <Skeleton animation="wave" width="72px" height="49px" />
                                    <Skeleton animation="wave" width="72px" height="49px" />
                                    <Skeleton animation="wave" width="72px" height="49px" />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Skeleton variant="rectangular" animation="wave" height={300} />
                    </CardContent>
                </Card>
            )}
        </>
    );
};

const SaveAndCloseButton = ({ handleSave, handleExit, isChanged, isFormError, isReadOnly, isLoading, disabled = false }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [delayingSave, setDelayingSave] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const saveOptions = useMemo(() => {
        if (isReadOnly) {
            return [{ label: "Close", type: "close", disabled: false, icon: <ExitToAppRoundedIcon /> }];
        }
        if (!isChanged) {
            return [
                { label: `Close`, type: "close", disabled: false, icon: <ExitToAppRoundedIcon /> },
                { label: "No Changes To Save", type: null, disabled: true, icon: false },
            ];
        }
        if (isFormError) {
            return [
                { label: `Close`, type: "close", disabled: false, icon: <ExitToAppRoundedIcon /> },
                { label: "Fix Errors To Save", type: null, disabled: true, icon: false },
            ];
        }
        return [
            { label: "Save", type: "save", disabled: false, icon: <SaveRoundedIcon /> },
            {
                label: "Save and Close",
                type: "saveExit",
                disabled: false,
                icon: <SaveRoundedIcon />,
            },

            {
                label: `Close Without Saving`,
                type: "close",
                disabled: false,
                icon: <ExitToAppRoundedIcon />,
            },
        ];
    }, [isChanged, isFormError, isReadOnly]);

    const handleClick = (index) => {
        const saveType = index ? saveOptions[index].type : saveOptions[selectedIndex].type;
        setDelayingSave(true);
        switch (saveType) {
            case "close":
                handleExit();
                break;
            case "save":
                setTimeout(() => {
                    handleSave();
                    setDelayingSave(false);
                }, 1000);
                break;
            case "saveExit":
                setTimeout(() => {
                    handleSave(handleExit);
                    setDelayingSave(false);
                }, 1000);
        }
    };

    return (
        <>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleClick(selectedIndex)}
                    startIcon={saveOptions[selectedIndex]?.icon}
                    disabled={saveOptions[selectedIndex]?.disabled || disabled}
                    loading={isLoading || delayingSave}
                >
                    {saveOptions[selectedIndex]?.label}
                </LoadingButton>
                {saveOptions?.length > 1 && (
                    <Button
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        disabled={isLoading || disabled || delayingSave}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                )}
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {saveOptions.map((option, index) => (
                                        <MenuItem key={index} disabled={option?.disabled} selected={index === selectedIndex} onClick={() => handleClick(index)}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

const DiscardChangesWarningModal = ({ showWarningModal, handleModalClose, handleDiscardChangesAndExit }) => {
    return (
        <Dialog open={showWarningModal} onClose={handleModalClose}>
            <DialogTitle>
                Discard Changes?
                <IconButton
                    aria-label="close-modal"
                    onClick={handleModalClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>Exiting will discard your unsaved changes. Would you like to exit anyway?</DialogContent>
            <DialogActions>
                <Button onClick={handleDiscardChangesAndExit} color="secondary">
                    Exit Anyway
                </Button>
                <Button onClick={handleModalClose} variant="contained">
                    Stay Here
                </Button>
            </DialogActions>
        </Dialog>
    );
};
