import { Box, Grid, TextField, InputAdornment, TableHead, TableRow, TableCell, TableBody, Skeleton, Typography, CircularProgress, Table, Card, CardContent, TableFooter, Tooltip } from "@mui/material";
import React, { useState, useMemo, useEffect, useCallback, memo } from "react";
import { useGetSieveSets, useGetSieveSetById } from "../../../queries/QualityControlQueries";
import { EntitySelect } from "../../general/EntitySelect";
import { isValidNumber, useSieveAnalysisCalculationHooks } from "./Calculations";
import { SampleSieveRangesTable } from "./SampleSieveRangesTable";
import { Sample, SieveSetAnalysis, SieveSet, Sieve, SieveAnalysis } from "../../../types/QualityControlModels";
import { SelectOption } from "../../../utilities/SelectUtilities";
import { handleInputFocus } from "../../general/UxUtilities";
import { isEqual, debounce } from "lodash";
import { Warning } from "@mui/icons-material";
import { VividTextField } from "./styled";

export const SampleSieveSetAnalysis = ({
    sample,
    sampleUpdate,
    isCoreSample,
    setSampleUpdate,
    setIsProcessing,
    showTitle = true,
    isReadOnly,
    disabled,
}: {
    sample: Sample;
    sampleUpdate: Sample;
    isCoreSample: boolean;
    setSampleUpdate: (value: any) => void;
    setIsProcessing: (value: boolean) => void;
    showTitle: boolean;
    isReadOnly: boolean;
    disabled: boolean;
}) => {
    const { calculateWeightLostInWash, calculatePercentLostInWash, calculateOversizeAndSlimes, calculateMoistureRate, calculateOversizePercent, calculateOrePercent } = useSieveAnalysisCalculationHooks();

    const [sieveSetAnalysis, setSieveSetAnalysis] = useState<SieveSetAnalysis>(sampleUpdate?.sieveSetAnalysis || ({} as SieveSetAnalysis));

    const { data: sieveSets, isLoading: isLoadingSieveSets }: { data: SieveSet[]; isLoading: boolean } = useGetSieveSets();

    const { data: sieveSet } = useGetSieveSetById({
        sieveSetId: sieveSetAnalysis?.sieveSetId,
        options: {
            enabled: !!sieveSetAnalysis?.sieveSetId,
        },
    });

    const oversizePercent = useMemo(() => {
        const oversizeWeight = sieveSetAnalysis?.oversizeWeight || 0;
        const preWashDryWeight = sieveSetAnalysis?.preWashDryWeight || 0;

        if (oversizeWeight && preWashDryWeight) {
            return calculateOversizePercent({
                oversizeWeight,
                preWashDryWeight,
            });
        }
    }, [calculateOversizePercent, sieveSetAnalysis?.oversizeWeight, sieveSetAnalysis?.preWashDryWeight]);

    const percentLostInWash = useMemo(() => {
        const preWashDryWeight = sieveSetAnalysis?.preWashDryWeight;
        const postWashDryWeight = sieveSetAnalysis?.postWashDryWeight;

        if (postWashDryWeight && preWashDryWeight) {
            return calculatePercentLostInWash({
                preWashDryWeight,
                postWashDryWeight,
                oversizePercent,
            });
        }
    }, [calculatePercentLostInWash, oversizePercent, sieveSetAnalysis?.postWashDryWeight, sieveSetAnalysis?.preWashDryWeight]);

    const weightLostInWash = useMemo(() => {
        const preWashDryWeight = sieveSetAnalysis?.preWashDryWeight;
        const postWashDryWeight = sieveSetAnalysis?.postWashDryWeight;
        const oversizeWeight = sieveSetAnalysis?.oversizeWeight ?? 0;
        if (postWashDryWeight && preWashDryWeight) {
            return calculateWeightLostInWash({
                preWashDryWeight,
                postWashDryWeight,
                oversizeWeight,
            });
        }
    }, [calculateWeightLostInWash, sieveSetAnalysis?.oversizeWeight, sieveSetAnalysis?.postWashDryWeight, sieveSetAnalysis?.preWashDryWeight]);

    const moistureRate = useMemo(() => {
        const preWashDryWeight = sieveSetAnalysis?.preWashDryWeight;
        const beginningWetWeight = sieveSetAnalysis?.beginningWetWeight;

        if (preWashDryWeight && beginningWetWeight) {
            return calculateMoistureRate({
                beginningWetWeight,
                preWashDryWeight,
            });
        }
    }, [calculateMoistureRate, sieveSetAnalysis?.beginningWetWeight, sieveSetAnalysis?.preWashDryWeight]);

    const orePercent = useMemo(() => {
        const preWashDryWeight = sieveSetAnalysis?.preWashDryWeight;
        const postWashDryWeight = sieveSetAnalysis?.postWashDryWeight;

        return calculateOrePercent({
            preWashDryWeight,
            postWashDryWeight,
        });
    }, [calculateOrePercent, sieveSetAnalysis?.postWashDryWeight, sieveSetAnalysis?.preWashDryWeight]);

    const handleOversizeWeightChange = useCallback((event) => {
        const oversizeWeight = event.target.value;
        setSieveSetAnalysis((prevState) => ({ ...prevState, oversizeWeight: parseFloat(oversizeWeight || 0) }));
    }, []);

    const handleWetWeightChange = useCallback((event) => {
        const beginningWetWeight = event.target.value.trim();
        setSieveSetAnalysis((prevState) => ({
            ...prevState,
            beginningWetWeight: parseFloat(beginningWetWeight || 0),
        }));
    }, []);

    const handlePreWashDryWeightChange = useCallback((event) => {
        const preWashDryWeight = event.target.value.trim();

        setSieveSetAnalysis((prevState) => ({
            ...prevState,
            preWashDryWeight: parseFloat(preWashDryWeight || 0),
        }));
    }, []);

    const handlePostWashDryWeightChange = useCallback((event) => {
        const postWashDryWeight = event.target.value.trim();
        setSieveSetAnalysis((prevState) => ({
            ...prevState,
            postWashDryWeight: parseFloat(postWashDryWeight || 0),
        }));
    }, []);

    const handleSplitSampleWeightChange = useCallback((event) => {
        const splitSampleWeight = event.target.value.trim();

        if (isValidNumber(splitSampleWeight)) {
            setSieveSetAnalysis((prevState) => ({
                ...prevState,
                splitSampleWeight: splitSampleWeight === "" ? null : parseFloat(splitSampleWeight),
            }));
        }
    }, []);

    const handleSelectSieveSet = useCallback((option: SelectOption) => {
        const sieveSetId = Number(option?.value);
        setSieveSetAnalysis((prevState) => ({ ...prevState, sieveSetId, sieveAnalyses: [] }));
    }, []);

    const activeSieveSets = useMemo(() => sieveSets?.filter((sieveSet) => sieveSet.isActive), [sieveSets]);

    //Best not to delay this that much
    useEffect(() => {
        if (!isEqual(sieveSetAnalysis, sample.sieveSetAnalysis)) {
            const timeoutId = setTimeout(() => {
                setIsProcessing(true);
                setSampleUpdate((prevState: Sample) => ({ ...prevState, sieveSetAnalysis }));
                setIsProcessing(false);
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [sample?.sieveSetAnalysis, sampleUpdate?.sieveSetAnalysis, setIsProcessing, setSampleUpdate, sieveSetAnalysis]);

    useEffect(() => {
        if (sieveSetAnalysis?.sieveAnalyses?.length < 1 && sieveSet?.sieves.length > 0) {
            setSieveSetAnalysis((prevState: SieveSetAnalysis) => ({
                ...prevState,
                sieveAnalyses: sieveSet?.sieves.map(
                    (sieve: Sieve): SieveAnalysis => ({
                        sieveId: sieve.id,
                        startWeight: sieve?.currentWeight || sieve?.initialWeight || 0,
                        sieve,
                        endWeight: 0,
                        finalWeight: 0,
                        percentFinal: 0,
                    })
                ),
            }));
        }
    }, [sieveSet]);

    useEffect(() => {
        setSieveSetAnalysis((prevState: SieveSetAnalysis) => ({ ...prevState, moistureRate }));
    }, [moistureRate]);

    return (
        <Card elevation={0}>
            <CardContent>
                <Grid container spacing={2}>
                    {showTitle && (
                        <Grid item xs={12}>
                            <Typography variant="h6">Sieve Analysis</Typography>
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <EntitySelect
                            label="Sieve Set"
                            entities={activeSieveSets}
                            isLoadingEntities={isLoadingSieveSets}
                            selectedValues={sieveSetAnalysis?.sieveSetId}
                            handleChange={handleSelectSieveSet}
                            filter={(sieveSet) => sieveSet.sites.some((site) => site.id === sampleUpdate?.location?.plant?.siteId)}
                            disabled={isReadOnly || disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Raw Sample Wet Weight"
                            type="number"
                            onFocus={handleInputFocus}
                            onChange={handleWetWeightChange}
                            value={sieveSetAnalysis?.beginningWetWeight || 0}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            size="small"
                            disabled={isReadOnly || disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Raw Sample Dry Weight"
                            type="number"
                            onFocus={handleInputFocus}
                            onChange={handlePreWashDryWeightChange}
                            value={sieveSetAnalysis?.preWashDryWeight || 0}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            size="small"
                            disabled={isReadOnly || disabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Washed Sand Dry Weight"
                            type="number"
                            onFocus={handleInputFocus}
                            onChange={handlePostWashDryWeightChange}
                            value={sieveSetAnalysis?.postWashDryWeight || 0}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            disabled={isReadOnly || disabled}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Washed Oversize Dry Weight"
                            type="number"
                            onFocus={handleInputFocus}
                            onChange={handleOversizeWeightChange}
                            value={sieveSetAnalysis?.oversizeWeight || 0}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            disabled={isReadOnly || disabled}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Washed Oversize"
                            type="number"
                            onFocus={handleInputFocus}
                            value={oversizePercent || 0}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: { step: 0.01 },
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                            }}
                            disabled
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Split Sample Weight"
                            type="number"
                            onFocus={handleInputFocus}
                            onChange={handleSplitSampleWeightChange}
                            value={sieveSetAnalysis?.splitSampleWeight || ""}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            disabled={isReadOnly || disabled}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Raw Sample Moisture"
                            type="number"
                            onFocus={handleInputFocus}
                            value={moistureRate || 0}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            disabled
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Washed Loss"
                            type="number"
                            onFocus={handleInputFocus}
                            value={weightLostInWash || 0}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            disabled
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <VividTextField
                            label="Washed Loss"
                            type="number"
                            onFocus={handleInputFocus}
                            value={percentLostInWash || 0}
                            InputProps={{
                                onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            disabled
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    {isCoreSample && (
                        <Grid item xs={3}>
                            <VividTextField
                                label="Ore Percent"
                                onFocus={handleInputFocus}
                                InputProps={{
                                    onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                value={orePercent || ""}
                                fullWidth
                                disabled
                                size="small"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {sieveSetAnalysis?.sieveSetId ? (
                            !!sieveSet?.sieves && (
                                <Box>
                                    <SieveAnalysesTable
                                        sieveSet={sieveSet}
                                        sieveSetAnalysis={sieveSetAnalysis}
                                        setSieveSetAnalysis={setSieveSetAnalysis}
                                        setIsProcessing={setIsProcessing}
                                        weightLostInWash={weightLostInWash}
                                        percentLostInWash={percentLostInWash}
                                        orePercent={orePercent}
                                        isReadOnly={isReadOnly}
                                        disabled={disabled}
                                    />
                                    {sieveSetAnalysis?.sieveAnalyses && !!sieveSetAnalysis?.sieveAnalyses.length && (
                                        <SampleSieveRangesTable sieveAnalyses={sieveSetAnalysis.sieveAnalyses} setSieveSetAnalysis={setSieveSetAnalysis} oversizePercent={oversizePercent} />
                                    )}
                                </Box>
                            )
                        ) : (
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        Select a Sieve Set to Continue
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const SieveAnalysesTable = ({
    sieveSet,
    sieveSetAnalysis,
    setSieveSetAnalysis,
    setIsProcessing,
    weightLostInWash,
    percentLostInWash,
    orePercent,
    isReadOnly,
    disabled,
}: {
    sieveSet: SieveSet;
    sieveSetAnalysis: SieveSetAnalysis;
    setSieveSetAnalysis: (value: any) => void;
    setIsProcessing: (value: boolean) => void;
    weightLostInWash: number;
    percentLostInWash: number;
    orePercent: number;
    isReadOnly: boolean;
    disabled: boolean;
}) => {
    const { sieveAnalyses } = sieveSetAnalysis;
    const [isAnalysisUpdating, setIsAnalysisUpdating] = useState(false);

    const { calculateSieveFinalWeight, calculateSievePercentFinal, calculateTotalWeight, calculateSplitSampleDelta } = useSieveAnalysisCalculationHooks();

    const sievesTotalWeight = useMemo(() => {
        if (sieveAnalyses?.some((sieveAnalysis: SieveAnalysis) => Number(sieveAnalysis.finalWeight) > 0)) {
            return calculateTotalWeight({ analyses: sieveAnalyses });
        }
        return 0;
    }, [calculateTotalWeight, sieveAnalyses]);

    const updateAnalysesAnalysis = useCallback(
        ({ index, updatedAnalysis }: { index: number; updatedAnalysis: SieveAnalysis }) => {
            setSieveSetAnalysis((prevState: SieveSetAnalysis) => {
                const updatedSieveAnalyses = prevState.sieveAnalyses.map((analysis, i) => (i === index ? { ...analysis, ...updatedAnalysis } : analysis));
                return {
                    ...prevState,
                    sieveAnalyses: updatedSieveAnalyses,
                };
            });
        },
        [setSieveSetAnalysis]
    );

    const handleStartWeightChange = useCallback(
        ({ event, index }) => {
            const startWeight = parseFloat(event.target.value);
            const updatedAnalysis: SieveAnalysis = { ...sieveAnalyses[index], startWeight };

            if (isValidNumber(startWeight)) {
                updateAnalysesAnalysis({
                    index,
                    updatedAnalysis,
                });
            }
        },
        [sieveAnalyses, updateAnalysesAnalysis]
    );

    const handleEndWeightChange = useCallback(
        ({ event, index }) => {
            const endWeight = parseFloat(event.target.value);
            const updatedAnalysis: SieveAnalysis = { ...sieveAnalyses[index], endWeight };

            if (isValidNumber(endWeight)) {
                updateAnalysesAnalysis({
                    index,
                    updatedAnalysis,
                });
            }
        },
        [sieveAnalyses, updateAnalysesAnalysis]
    );

    /**
     *  For Camsizer Only
     */
    const handleFinalWeightChange = useCallback(
        ({ event, index }) => {
            const finalWeight = parseFloat(event.target.value);
            const updatedAnalysis: SieveAnalysis = { ...sieveAnalyses[index], finalWeight };

            if (isValidNumber(finalWeight)) {
                updateAnalysesAnalysis({
                    index,
                    updatedAnalysis,
                });
            }
        },
        [sieveAnalyses, updateAnalysesAnalysis]
    );

    useEffect(() => {
        setIsAnalysisUpdating(true);
        setIsProcessing(true);

        const updateFunction = () => {
            const updatedAnalyses = sieveAnalyses.map((analysis) => {
                const startWeight = analysis.startWeight;
                const endWeight = analysis.endWeight;

                let finalWeight = analysis?.finalWeight;
                if (!sieveSet?.isCamsizer) {
                    finalWeight = calculateSieveFinalWeight({
                        startWeight,
                        endWeight,
                    });
                }

                const percentFinal = calculateSievePercentFinal({
                    sieveFinalWeight: finalWeight,
                    sievesTotalWeight,
                    orePercent,
                    isPan: analysis.sieve?.isPan,
                    percentLostInWash,
                });

                // Skip if we just calculated the same thing
                if (isEqual(analysis?.percentFinal, percentFinal)) {
                    return analysis; // Skip the current analysis, no changes needed
                }

                return {
                    ...analysis,
                    finalWeight,
                    percentFinal,
                };
            });

            if (!isEqual(updatedAnalyses, sieveAnalyses)) {
                setSieveSetAnalysis((prevState: SieveSetAnalysis) => ({
                    ...prevState,
                    totalWeight: calculateTotalWeight({ analyses: updatedAnalyses }),
                    sieveAnalyses: updatedAnalyses,
                }));
            }
            setIsAnalysisUpdating(false);
            setIsProcessing(false);
        };

        const debouncedUpdate = debounce(() => {
            updateFunction();
        }, 1000);

        debouncedUpdate();

        return () => {
            debouncedUpdate.cancel();
        };
    }, [
        sieveAnalyses,
        orePercent,
        sievesTotalWeight,
        calculateSievePercentFinal,
        setSieveSetAnalysis,
        setIsProcessing,
        sieveSet?.isCamsizer,
        weightLostInWash,
        percentLostInWash,
        calculateSieveFinalWeight,
        calculateTotalWeight,
    ]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Screen Size</TableCell>
                    {!sieveSet?.isCamsizer && (
                        <>
                            <TableCell>Start Weight</TableCell>
                            <TableCell>End Weight</TableCell>
                        </>
                    )}
                    <TableCell>{sieveSet?.isCamsizer ? "Camsizer" : "Final Weight"}</TableCell>
                    <TableCell>Percent Final</TableCell>
                    {/* <TableCell>30 Day Avg</TableCell>
                    <TableCell>30 Day Std</TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
                {sieveAnalyses
                    .sort((a, b) => a.sieve?.displayOrder - b.sieve?.displayOrder)
                    .map((sieveAnalysis: SieveAnalysis, index: number) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>{sieveAnalysis?.sieve?.size}</TableCell>

                                {!sieveSet?.isCamsizer ? (
                                    <>
                                        <TableCell>
                                            <VividTextField
                                                required
                                                type="number"
                                                value={sieveAnalysis?.startWeight || 0}
                                                InputProps={{
                                                    onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                                    endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                                }}
                                                onFocus={handleInputFocus}
                                                onChange={(event) =>
                                                    handleStartWeightChange({
                                                        index,
                                                        event,
                                                    })
                                                }
                                                disabled={isReadOnly || disabled}
                                                size="small"
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <VividTextField
                                                required
                                                type="number"
                                                onScroll={() => false}
                                                value={sieveAnalysis?.endWeight || 0}
                                                InputProps={{
                                                    onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                                    endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                                }}
                                                onFocus={handleInputFocus}
                                                onChange={(event) =>
                                                    handleEndWeightChange({
                                                        index,
                                                        event,
                                                    })
                                                }
                                                disabled={isReadOnly || disabled}
                                                size="small"
                                                fullWidth
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <TableCell>
                                    <VividTextField
                                        value={sieveAnalysis?.finalWeight}
                                        onFocus={handleInputFocus}
                                        onChange={(event) =>
                                            handleFinalWeightChange({
                                                index,
                                                event,
                                            })
                                        }
                                        InputProps={{
                                            onWheel: (event) => (event.target as HTMLInputElement).blur(),
                                            endAdornment: (
                                                <>
                                                    {Number(sieveAnalysis?.finalWeight) < 0 && (
                                                        <Tooltip title="Final weight cannot be negative" arrow>
                                                            <InputAdornment position="start">
                                                                <Warning color="error" />
                                                            </InputAdornment>
                                                        </Tooltip>
                                                    )}
                                                    <InputAdornment position="end">g</InputAdornment>
                                                </>
                                            ),
                                        }}
                                        disabled={!sieveSet?.isCamsizer || disabled || isReadOnly}
                                        size="small"
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <LoadingTextField
                                        value={!isAnalysisUpdating ? (isFinite(Number(sieveAnalysis.percentFinal)) ? sieveAnalysis.percentFinal : 0) : ""}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                        isLoading={isAnalysisUpdating}
                                        size="small"
                                        fullWidth
                                        disabled
                                    />
                                </TableCell>
                                {/* <TableCell></TableCell>
                                <TableCell></TableCell> */}
                            </TableRow>
                        );
                    })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    {!sieveSet?.isCamsizer ? (
                        <>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Total</TableCell>
                        </>
                    ) : (
                        <TableCell>Total</TableCell>
                    )}
                    <TableCell>
                        <VividTextField
                            value={sievesTotalWeight}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            disabled
                            size="small"
                            fullWidth
                        />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                <TableRow>
                    {!sieveSet?.isCamsizer ? (
                        <>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </>
                    ) : (
                        <></>
                    )}

                    <TableCell>Split Sample</TableCell>
                    <TableCell>
                        <VividTextField
                            value={sieveSetAnalysis?.splitSampleWeight || ""}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            disabled
                            size="small"
                            fullWidth
                        />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                <TableRow>
                    {!sieveSet?.isCamsizer ? (
                        <>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </>
                    ) : null}

                    <TableCell>Detla Δ</TableCell>
                    <TableCell>
                        <VividTextField
                            value={calculateSplitSampleDelta({
                                totalWeight: sievesTotalWeight,
                                splitSampleWeight: sieveSetAnalysis?.splitSampleWeight,
                            })}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}
                            disabled
                            size="small"
                            fullWidth
                        />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
};

export const SampleSieveAnalysisSkeleton = () => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h6">
                <Skeleton />
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton />
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

const LoadingTextField = ({ isLoading, ...props }) => {
    return (
        <div style={{ position: "relative", display: "inline-flex", width: "100%" }}>
            {isLoading && (
                <CircularProgress
                    size={20}
                    style={{
                        color: "rgba(83,83,83, 0.38)",
                        position: "absolute",
                        top: "50%",
                        left: "10%",
                        marginTop: -10,
                        marginLeft: -10,
                    }}
                />
            )}
            <VividTextField fullWidth {...props} />
        </div>
    );
};
