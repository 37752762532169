import { Chip, CircularProgress, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useGetRepeatabilityCountByUser } from "../../../queries/QualityControlQueries";
import { useUserInfo } from "../../../hooks/general/useUserInfo";

// REPEATABILITY counter is showing the number of samples the lab tech has completed since they last repeated a sample
export const RepeatabilityCounter = () => {
    const { userInfo } = useUserInfo();
    console.log({ userInfo });
    const { data: samplesSinceLastRepeat, isLoading: isRepeatabilityCountLoading } = useGetRepeatabilityCountByUser(userInfo?.id);
    const isRepeatNeeded = samplesSinceLastRepeat > 99;
    const label = useMemo(() => <Typography>Repeatability: {isRepeatabilityCountLoading ? <CircularProgress size={14} /> : samplesSinceLastRepeat}</Typography>, [samplesSinceLastRepeat]);
    return <Chip label={label} color={isRepeatNeeded ? "error" : "default"} />;
};
