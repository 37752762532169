import React, { useEffect, useMemo } from "react";
import { AsyncSelect } from "./AsyncSelect";
import { useGetUsersInGroup } from "../../queries/AdminQueries";
import { useAcquireAccessToken } from "../../hooks/general/useAcquireAccessToken";
import { useUserHasPermission } from "../../hooks/auth/useUserHasPermission";
import { PERMISSIONS } from "../../hooks/auth/useUserRoles";
import { SelectOption } from "../../utilities/SelectUtilities";

export const UserSelect = ({
    userGroup = null,
    userRole = null,
    selectedUser,
    handleUserChange,
    disabled,
    isLoading = false,
    size = "",
}: {
    userGroup?: string;
    userRole?: string;
    selectedUser: string | number;
    handleUserChange: (_: any, user: SelectOption) => void;
    disabled: boolean;
    isLoading?: boolean;
    size?: string;
}) => {
    const silicoreUsersGroupId = process.env.REACT_APP_WRITERS_GROUP_ID;
    const adminGroupId = process.env.REACT_APP_ADMINS_GROUP_ID;
    const { accessToken } = useAcquireAccessToken();
    const { data: labtechs } = useGetUsersInGroup({ accessToken, groupId: silicoreUsersGroupId });
    useEffect(() => console.log({ labtechs }), [labtechs]);
    const { data: admins } = useGetUsersInGroup({ accessToken, groupId: adminGroupId });
    const canManage = useUserHasPermission(PERMISSIONS.MANAGE);
    const options = useMemo(() => {
        if (!canManage || !admins || !admins.length) {
            return labtechs?.filter((user) => user?.["@odata.type"] !== "#microsoft.graph.group")?.map((user) => ({ value: user?.id, label: user?.displayName })) ?? [];
        }
        return (
            admins
                .concat(labtechs)
                ?.filter((user) => user?.["@odata.type"] !== "#microsoft.graph.group")
                ?.map((user) => ({ value: user?.id, label: user?.displayName })) ?? []
        );
    }, [labtechs, admins, canManage]);

    const label = userRole ?? "User";

    const selected = options?.find((option) => option?.value === selectedUser) ?? null;

    return <AsyncSelect label={label} options={options} selectedOptions={selected} handleChange={handleUserChange} isLoading={isLoading} disabled={disabled} size={size} />;
};
